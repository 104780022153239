import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";
import HowItWorksHero from "root/sections/HowItWorksHero";
import HowItWorksGetStarted from "root/sections/HowItWorksGetStarted";
import HowItWorksSteps from "root/sections/HowItWorksSteps";
import Footer from "root/sections/Footer";

const METATAGS = {
  title: "Drivit | How It Works",
  description:
    "Ready to experience the power of technology? With top-notch development, Drivit is easy to use, requires no hardware, and brings all the data and analytics you want and need.",
  keywords:
    "Driving app, Driving analytics, Driving SDK, Mobility SDK, Driving white-label, Mobility white-label, Telematics tools, Hardware-free telematics, Machine learning, Artificial intelligence driving analytics, AI driving analytics, Artificial intelligence telematics, AI telematics",
};

const HowItWorksPage = () => {
  return (
    <div>
      <SEO
        title={METATAGS.title}
        description={METATAGS.description}
        keywords={METATAGS.keywords}
      />
      <Layout>
        <Header currentPage="how-it-works" background="darkGreen" />
        <HowItWorksHero />
        <HowItWorksSteps />
        <HowItWorksGetStarted />
        <Footer />
      </Layout>
    </div>
  );
};

export default HowItWorksPage;
