import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import step1Img from "root/assets/how-it-works-step1.svg";
import step2Img from "root/assets/how-it-works-step2.svg";
import step3Img from "root/assets/how-it-works-step3.svg";
import numberCircle from "root/assets/how-it-works-cirlce.svg";
import separatorPath from "root/assets/how-it-works-separator.svg";
import separator2Path from "root/assets/how-it-works-separator2.svg";
import separator3Path from "root/assets/how-it-works-separator3.svg";
import HowItWorksSeparator from "root/components/SvgImages/HowItWorksSeparator";
import HowItWorksSeparator2 from "root/components/SvgImages/HowItWorksSeparator2";
import supportSmil from "root/utils/supportSmil";

import styles from "./index.module.css";

const HowItWorksSteps = () => {
  const data = useStaticQuery(graphql`
    query {
      step4Img: file(relativePath: { eq: "how-it-works-step4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 678, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Section>
      <div className={styles.firstStep}>
        <div className={styles.back}>
          <img
            className={styles.separatorImgMobile}
            alt=""
            src={separator3Path}
          />
        </div>
        <div className={styles.leftSide}>
          <div className={styles.number}>
            {supportSmil() ? (
              <div className={styles.separatorImg}>
                <HowItWorksSeparator />
              </div>
            ) : (
              <img className={styles.separatorImg} alt="" src={separatorPath} />
            )}
            <img className={styles.numberCircle} alt="" src={numberCircle} />
            <div className={styles.numberText}>
              <Typography color="green" variant="h3" weight="bold">
                1
              </Typography>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.textTitle}>
              <Typography color="black" variant="h2" weight="bold">
                Choose how to integrate Drivit
              </Typography>
            </div>
            <Typography>
              Easily integrate Drivit SDKs into your own apps or use its
              white-labels to accelerate the deployment of your new mobility
              product.
            </Typography>
          </div>
        </div>
        <div className={styles.rightSide}>
          <img className={styles.step1Img} alt="" src={step1Img} />
        </div>
      </div>

      <div className={styles.secondStep}>
        <div className={styles.back}>
          <img
            className={styles.separatorImgMobile2}
            alt=""
            src={separator3Path}
          />
        </div>
        <div className={styles.rightSideStep2}>
          <img className={styles.step2Img} alt="" src={step2Img} />
        </div>
        <div className={styles.leftSide}>
          <div className={styles.number}>
            {supportSmil() ? (
              <div className={styles.separator2Img}>
                <HowItWorksSeparator2 />
              </div>
            ) : (
              <img
                className={styles.separator2Img}
                alt=""
                src={separator2Path}
              />
            )}
            <img className={styles.numberCircle} alt="" src={numberCircle} />
            <div className={styles.numberText}>
              <Typography color="green" variant="h3" weight="bold">
                2
              </Typography>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.textTitle}>
              <Typography color="black" variant="h2" weight="bold">
                Handpick the building blocks for your app
              </Typography>
            </div>
            <Typography>
              From our unique safety and consumption metrics, based on digital
              signal processing and machine learning, to third-party enabled
              features, like route optimization to EV recharge or driving advice
              based on weather and traffic state, we’ve got you covered.
            </Typography>
          </div>
        </div>
      </div>

      <div className={styles.thirdStep}>
        <div className={styles.back}>
          <img
            className={styles.separatorImgMobile3}
            alt=""
            src={separator3Path}
          />
        </div>
        <div className={styles.leftSide}>
          <div className={styles.number}>
            {supportSmil() ? (
              <div className={styles.separatorImg}>
                <HowItWorksSeparator />
              </div>
            ) : (
              <img className={styles.separatorImg} alt="" src={separatorPath} />
            )}
            <img className={styles.numberCircle} alt="" src={numberCircle} />
            <div className={styles.numberText}>
              <Typography color="green" variant="h3" weight="bold">
                3
              </Typography>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.textTitle}>
              <Typography color="black" variant="h2" weight="bold">
                Build your 360º perspective of your drivers
              </Typography>
            </div>
            <Typography>
              We make available a set of telematics tools, ranging from our APIs
              to our driver portal, that enables you to consume the driving and
              mobility data of your drivers at your pace and according to your
              needs.
            </Typography>
          </div>
        </div>
        <div className={styles.rightSide}>
          <img className={styles.step3Img} alt="" src={step3Img} />
        </div>
      </div>

      <div className={styles.fourthStep}>
        <div className={styles.rightSideStep2}>
          <Img
            className={styles.step4Img}
            alt="team meeting"
            fluid={data.step4Img.childImageSharp.fluid}
            objectFit="cover"
          />
        </div>
        <div className={styles.leftSide}>
          <div className={styles.number}>
            <img className={styles.numberCircle} alt="" src={numberCircle} />
            <div className={styles.numberText}>
              <Typography color="green" variant="h3" weight="bold">
                4
              </Typography>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.textTitle}>
              <Typography color="black" variant="h2" weight="bold">
                Launch innovation with peace of mind
              </Typography>
            </div>
            <Typography>
              After launch, we guarantee you a close support and continuous
              evolution of our services and unique feature, so that you can
              focus on what matters, delivering the best services and
              experiences to your drivers.
            </Typography>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HowItWorksSteps;
