import React from "react";

import styles from "./howItWorksSeparator.module.css";

const HowItWorksSeparator2 = () => {
  return (
    <svg
      className={styles.root}
      viewBox="0 0 507 462"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path id="hiwSeparator2" d="M505 0V344H1.5V460" pathLength="963.5" />

        <radialGradient
          id="fadeCar3"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" stopOpacity="1" offset="0" />
          <stop stopColor="#5BBA6F" stopOpacity="0.5" offset="0.5" />
          <stop stopColor="#5BBA6F" stopOpacity="0" offset="1" />
        </radialGradient>

        <mask id="car9" maskUnits="userSpaceOnUse">
          <use className={styles.maskCarRoute8} xlinkHref="#hiwSeparator2">
            <animate
              attributeName="stroke-dashoffset"
              from="63.5"
              to="-900"
              dur="8s"
              repeatCount="indefinite"
            />
          </use>
        </mask>
      </defs>

      <g style={{ mask: "url(#car9)" }}>
        <circle style={{ fill: "url(#fadeCar3)" }} cx="0" cy="0" r="100">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur="8s"
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#hiwSeparator2" />
          </animateMotion>
        </circle>
      </g>

      <path
        d="M505 0V344H1.5V460"
        pathLength="963.5"
        className={styles.maskTrailRoute8}
      >
        <animate
          attributeName="stroke-dashoffset"
          from="356"
          to="-607.5"
          dur="8s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default HowItWorksSeparator2;
