import React from "react";
import { Link } from "gatsby";

import Typography from "root/components/Typography";
import arrow from "root/assets/arrow.svg";

import styles from "./index.module.css";

const HowItWorksGetStarted = () => {
  return (
    <Link className={styles.link} to="/get-started">
      <div className={styles.greenSection}>
        <div className={styles.contentGreenSection}>
          <Typography color="white" variant="h3">
            Let&apos;s Drivit together
          </Typography>
          <div className={styles.joinDrivit}>
            <Typography color="white" variant="h1" weight="bold">
              Get Started
            </Typography>
            <img src={arrow} alt="" className={styles.arrow} />
          </div>
        </div>
        <div className={styles.contentGreenSectionDynamic}>
          <div className={styles.textGreenSectionDynamic}>
            <div className={styles.contentGreenSection}>
              <Typography color="white" variant="h3">
                Let&apos;s Drivit together
              </Typography>
              <div className={styles.joinDrivit}>
                <Typography color="white" variant="h1" weight="bold">
                  Get Started
                </Typography>
                <img src={arrow} alt="" className={styles.arrow} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default HowItWorksGetStarted;
