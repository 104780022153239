import React from "react";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import heroImg from "root/assets/how-it-works-hero.svg";

import styles from "./index.module.css";

const HowItWorksHero = () => {
  return (
    <Section color="darkGreen">
      <div className={styles.hero}>
        <Typography variant="h1" color="white">
          How it works
        </Typography>
        <div className={styles.heroTitle}>
          <Typography variant="h1" color="white" weight="bold">
            Easy to use, hardware-free
          </Typography>
        </div>
        <img className={styles.heroImg} alt="" src={heroImg} />
      </div>
    </Section>
  );
};

export default HowItWorksHero;
