const supportSmil = () => {
  if (typeof document === "undefined") return true;

  return (
    document
      .createElementNS("http://www.w3.org/2000/svg", "animate")
      .toString()
      .indexOf("SVGAnimate") > -1
  );
};

export default supportSmil;
